import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/src/app/components/Header/components/MobileMenu/MobileMenu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Header/components/Navigation/Navigation.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/vercel/path0/src/app/provider/provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/CommonLink/CommonLink.module.scss");
