'use client';

import { ButtonType, ButtonVariants } from './types';
import styles from './Button.module.scss';
import { ReactNode } from 'react';

const variants: ButtonType = {
  clear: styles.clear
};

interface ButtonProps {
  variant: ButtonVariants;
  handleClick: () => void;
  children: ReactNode;
  className?: string;
}

export const Button = ({ variant, handleClick, children, className = '', ...props }: ButtonProps) => {
  return (
    <button
      onClick={() => handleClick()}
      className={`${variants[variant]} ${className} ${styles.commonStyles}`}
      {...props}
    >
      {children}
    </button>
  );
};
