import { navData } from '@/src/app/components/Header/components/Navigation/constants';
import { CommonLink } from '../../../CommonLink/CommonLink';
import styles from './Navigation.module.scss';
import { CommonLinkSizeVariants } from '../../../CommonLink/types';

interface NavigationProps {
  isMobile?: boolean;
}

export const Navigation = ({ isMobile = false }: NavigationProps) => {
  return (
    <nav>
      <ul className={`${isMobile ? styles.navListMobile : styles.navListDesktop}`}>
        {navData.map(({ title, link, isBlank }) => (
          <li className={styles.navItem} key={title}>
            <CommonLink link={link} size={CommonLinkSizeVariants.DEFAULT} isBlank={isBlank} className={styles.navLink}>
              {title}
            </CommonLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
