import { Links } from '@/src/app/constants/links';

export const navData = [
  {
    title: 'Potions',
    link: Links.POTIONS,
    isBlank: false
  },
  // { title: 'Bridge', link: Links.BRIDGE, isBlank: false },
  { title: 'Docs', link: Links.DOCS, isBlank: true }
];
