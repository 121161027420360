export const modalVariants = {
  hidden: {
    x: '100%'
  },
  visible: {
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  exit: {
    x: '100%',
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeInOut'
    }
  }
};
